<template>
  <v-dialog v-model="editDialog" persistent max-width="600">
    <v-card :loading="loading">
      <v-card-title>edit {{ name }}</v-card-title>
      <v-card-text>
        <v-text-field label="name" v-model="view.name" disabled></v-text-field>
        <v-text-field label="description" v-model="view.description"></v-text-field>
        <v-textarea outlined counter="250" maxLength="250" label="brief" v-model="view.brief"></v-textarea>
        <v-switch
      v-model="view.active"
      :label="`Active: ${view.active.toString()}`"
    ></v-switch>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    editDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      view: {
        name: null,
        description: null,
        brief: null,
        active: false
      },
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.general.subscriberTypes
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.view.name = data.name;
          this.view.description = data.description;
          this.view.brief = data.brief;
          this.view.active = data.active;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = true;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let payload = { id: this.myId, state: false, data: this.view };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
